import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import mapboxgl from "mapbox-gl"

class BlogIndex extends React.Component {
  createMap() {
    const { data } = this.props
    const locations = data.allContentfulLocations.edges

    var beenLocations = locations.reduce(function (result, { node }) {
      if (node.status === "Been") {
        result.push(node)
      }
      return result
    }, [])

    var wantLocations = locations.reduce(function (result, { node }) {
      if (node.status === "Want") {
        result.push(node)
      }
      return result
    }, [])

    var been = beenLocations.map(node => ({
      type: "Feature",
      title: node.title,
      properties: {
        title: node.title,
        description: node.title,
        slug: node.slug,
      },
      geometry: {
        type: "Point",
        coordinates: [node.geocode.lon, node.geocode.lat],
      },
    }))

    var want = wantLocations.map(node => ({
      type: "Feature",
      title: node.title,
      properties: {
        title: node.title,
        description: node.title,
        slug: node.slug,
      },
      geometry: {
        type: "Point",
        coordinates: [node.geocode.lon, node.geocode.lat],
      },
    }))

    const zoom = 4
    const coordinates = [-2.884845, 54.037619]
    const map = new mapboxgl.Map({
      center: coordinates,
      container: "map",
      style: "mapbox://styles/mapbox/streets-v9",
      zoom: zoom,
    })

    map.on("load", function () {
      map.addLayer({
        id: "Places I have Been",
        type: "circle",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: been,
          },
        },

        paint: {
          "circle-radius": 7,
          "circle-color": "#007cbf",
          "circle-opacity": 0.8,
          "circle-stroke-width": 0,
        },
      })
      map.addLayer({
        id: "Places I Want to Visit",
        type: "circle",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: want,
          },
        },

        paint: {
          "circle-radius": 7,
          "circle-color": "#ff0000",
          "circle-opacity": 0.8,
          "circle-stroke-width": 0,
        },
      })
    })
    map.addControl(new mapboxgl.NavigationControl())

    var toggleableLayerIds = ["Places I have Been", "Places I Want to Visit"]

    for (var i = 0; i < toggleableLayerIds.length; i++) {
      var id = toggleableLayerIds[i]

      var link = document.createElement("a")
      link.href = "#"
      link.className = "active"
      link.textContent = id

      link.onclick = function (e) {
        var clickedLayer = this.textContent
        e.preventDefault()
        e.stopPropagation()

        var visibility = map.getLayoutProperty(clickedLayer, "visibility")

        if (visibility === "visible") {
          map.setLayoutProperty(clickedLayer, "visibility", "none")
          this.className = ""
        } else {
          this.className = "active"
          map.setLayoutProperty(clickedLayer, "visibility", "visible")
        }
      }

      var layers = document.getElementById("layers")
      layers.appendChild(link)
    }

    map.on("click", "Places I have Been", function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice()
      var description = "<h3>" + e.features[0].properties.description + "</h3>"
      var locationLink =
        "<a href=/location/" +
        e.features[0].properties.slug +
        ">click for info</a>"

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description + locationLink)
        .addTo(map)
      var clickedPoint = e.features[0]
      flyToStore(clickedPoint)
    })

    function flyToStore(currentFeature) {
      map.flyTo({
        center: currentFeature.geometry.coordinates,
      })
    }

    map.on("mouseenter", "Places I have Been", function () {
      map.getCanvas().style.cursor = "pointer"
    })

    map.on("mouseleave", "Places I have Been", function () {
      map.getCanvas().style.cursor = ""
    })

    map.on("click", "Places I Want to Visit", function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice()
      var description = "<h3>" + e.features[0].properties.description + "</h3>"
      var locationLink =
        "<a href=/location/" +
        e.features[0].properties.slug +
        ">click for info</a>"

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description + locationLink)
        .addTo(map)
    })

    map.on("mouseenter", "Places I Want to Visit", function () {
      map.getCanvas().style.cursor = "pointer"
    })

    map.on("mouseleave", "Places I Want to Visit", function () {
      map.getCanvas().style.cursor = ""
    })
  }

  componentDidMount() {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic2VyaXRoIiwiYSI6ImNqcGI1eXN4dDBtcWwzcXAyNGsycG0xaWgifQ.Qg3PAYHQ72Qem7VbWCb0ZQ"
    this.createMap()
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description

    const locations = data.allContentfulLocations.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title="Locations"
          keywords={["blog", "gatsby", "javascript", "react"]}
        />

        <h1>Locations</h1>

        <div className="mapContainer">
          <nav id="layers"></nav>
          <div id="map" className="map"></div>
        </div>
        <div className="maplist">
          <div className="been">
            <h2>Place I have Been</h2>
            <ul>
              {locations.map(({ node }) => {
                if (node.status === "Been") {
                  return <li key={node.id}>{node.title}</li>
                }
              })}
            </ul>
          </div>
          <div className="want">
            <h2>Place I Want To Visit</h2>
            <ul>
              {locations.map(({ node }) => {
                if (node.status === "Want") {
                  return <li key={node.id}>{node.title}</li>
                }
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulLocations(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          status
          slug
          createdAt
          geocode {
            lon
            lat
          }
        }
      }
    }
  }
`
